import { Component, OnInit } from '@angular/core';
import { Work } from '../../models/Work';

@Component({
  selector: 'app-work',
  templateUrl: './work.component.html',
  styleUrls: ['./work.component.scss']
})
export class WorkComponent implements OnInit {

  work:Work[];

  constructor() { }

  ngOnInit(): void {
    this.work = [
      {
        id:1,
        title:'we',
        text:'instagram for sustainability'
      },
      {
        id:2,
        title:'Blumenbrauer',
        text:'instagram for sustainability'
      },
      {
        id:3,
        title:'vree.',
        text:'instagram for sustainability'
      }
    ]
  }

}
