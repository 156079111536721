<div class="con space-8">
  <a routerLink="/"><p><img class="back" src="/assets/img/top.png">back</p></a>
</div>


<div class="center bg">
<img  class="space-1" id="fafa-start" src="assets/img/fafa-01.png">
</div>

<div class="con space-2">
  <h1>Fafa</h1>
  <h3>Creating an inspiring and surprising interaction that makes fun - for children and grown ups. Translating sounds into abstract street art.</h3>
  <div class="row space-2">
      <div class="col-6">
  <h4>overview</h4>
  <p>The project was developed with an Arduino and additional sensors. The aim was to design an interactive product.
</p>
<p>More coming soon..</p>


      </div>
      <div class="col-1"></div>
      <div class="col-5">
          <h4>role</h4>
          <p>concept, design, electric, CAD modelling, prototyping</p>
           <p class="space-1">2021</p>

        </div>
    </div>





  </div>

