<footer class="space-8">
<div class="con text-centered">
    <h3 >Feel free to reach out for collaborations <br>or just
      a friendly hello. &#9996;  </h3>

      <a href="mailto:lisapfaeffel@web.de"><img class="btn-link" id="mail" src="assets/img/mail.png"></a>
      <a  href="http://linkedin.com/in/lisa-pfäffel-4855491a7"><img class="btn-link" id="linkedin" src="assets/img/linkedin.png"></a>

  <p class="space-4">© 2021 All rights reserved – designed and coded by Lisa Pfäffel</p>
  <p ><a id="impressum-link"  routerLink="/impressum">Impressum </a></p>
  <a (click)="scrolltotop();"><img id="top" src="assets/img/top.png"></a>
</div>
<div class="space-4"></div>

</footer>
