<div class="con space-8">
  <div class="row">


<div class="col-lg-6"><h1>A little bit about myself 👋</h1>
  <h3 class="space-2">My name is Lisa Pfäffel and I'm passionate about research and design. Curiosity and the ambition to make peoples' lives better drives me. I'm a <a routerLink="" class="link">UX Designer</a>, currently studying User Experience Design (B. Sc.) at the Technische Hochschule Ingolstadt.</h3>


</div>

<div class="col-lg-6 con-me">
    <img id="img-me"  src="assets/img/me-round.png">
</div>

</div>


<h2 class="space-6">Tools & Skills</h2>
  <h4>Prototyping & Designing <span class="p opacity-50">Figma, XD, Photoshop, Illustrator, InDesign, PremierePro, Lightroom.</span></h4>
  <h4>Coding <span class="p opacity-50">HTML, CSS, JavaScript, Java, Bootstrap, Angular.</span></h4>



  <h2 class="space-6"> My values</h2>


<h4 class="space-2">🤓 Always be learning.</h4>
<p class="opacity-50"> I always had a huge attraction to technology and design. So what did I do after school?
    Well, something that has both in its name - I became a technical product designer. From drafting to modeling to final renderings, these projects shaped my understanding of the design world. I can’t count how many times my background made me a versatile team player. After I collected some experiences in this business for nearly five years I decided to start something new.</p>

    <h4 class="space-2">😍 Passion is everything.</h4>
    <p class="opacity-50">Life is about creating yourself, it's not about finding yourself. So if you don't enjoy what you're doing - move. That's why I went from physical to digital.
        Inspired by people and their stories, I hope to make the world a better place through design. I thrive on great design. This means much more than looking nice.
        Nothing excites me like a smart product that solves a real problem.
    </p>

<h4 class="space-2">🎨 Embrace your creativity.</h4>
<p class="opacity-50">When I'm not designing, I'm drawing, photographing or cooking. To integrate mindfulness in everyday life I love spending time in nature and practicing yoga.
  On the other hand I really value the time I can spend with my volunteer work at the Tafel supporting people in need of food. The personal exchange is so valueable for me.
</p>


</div>

