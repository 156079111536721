import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { WorkComponent } from './components/work/work.component';
import { ImpressumComponent } from './components/impressum/impressum.component';
import { AboutComponent } from './components/about/about.component';
import { WeComponent } from './components/we/we.component';
import { VreeComponent } from './components/vree/vree.component';
import { DybtComponent } from './components/dybt/dybt.component';
import { FafaComponent } from './components/fafa/fafa.component';



const routes: Routes = [
  {path:'', component: WorkComponent},
  {path: 'impressum', component: ImpressumComponent},
  {path: 'about', component: AboutComponent},
  {path: 'we', component: WeComponent},
  {path: 'vree', component: VreeComponent},
  {path: 'dybt', component: DybtComponent},
  {path: 'fafa', component: FafaComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }


