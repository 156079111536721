
<div class="con space-8">
  <h1>Hi! My name is <a routerLink="/about" class="link">Lisa Pfäffel</a>,
    a UX designer exploring and living in Ingolstadt, Germany. </h1>
  <h3 class="space-2">I'm passionate about improving the lives of others through research and design,
    constantly looking for new things everyday.</h3>




  <h3 class="space-2">Take a look at my recent works!</h3>

<div class="space-6"></div>


  <div class="card click orange">
    <a routerLink="/we">
    <div class="row">
        <div class="col-lg-6 text">
          <div class="con-text">
            <h5>minimum loveable product</h5>
            <h1>we</h1>
              <p>We do not act according to our values. The answer to this problem statement is we, a social network for a new way to live sustainability.
              </p>
              <div class ="space-4"></div>
          </div>

          </div>
          <div class="col-lg-6 wrapper-bottom">
        <img src="assets/img/work-we.png" id="screen-we">
      </div>

  </div>
</a>
</div>

<div class="card click green">
  <a routerLink="/vree">
    <div class="row">

      <div class="col-lg-6 wrapper-bottom">

      <img src="assets/img/work-vree.png" id="screen-vree">
    </div>
    <div class="col-lg-6 text">
<div class="con-text">
  <h5>human machine interaction</h5>
  <h1>vree.</h1>
    <p>Creating an interactive interface for boxing gadgets. We combined boxing with freedom and what was the result? vree!</p>
<div class ="space-4"></div>
</div>
      </div>
  </div>
</a>
</div>

<div class="card click dybt">
  <a routerLink="/dybt">
    <div class="row">

    <div class="col-lg-6 text">
      <div class="con-text">
        <h5>ethical user experience</h5>
        <h1>dybt</h1>
          <p>We love content with real added value. Here, authenticity
            and depth of content counts more than retouched perfection. Hands on ethical UX!</p>
            <div class ="space-4"></div>
      </div>
            </div>
            <div class="col-lg-6 wrapper-bottom">

              <img src="assets/img/dybt.png" id="screen-vree">
            </div>
  </div>
</a>
</div>

<div class="card click fafa">
  <a routerLink="/fafa">
  <div class="row">

        <div class="col-lg-6 wrapper-bottom">
      <img src="assets/img/fafa-work.png" id="screen-we">
    </div>
    <div class="col-lg-6 text">
      <div class="con-text">
        <h5>interactive product design</h5>
        <h1>Fafa</h1>
          <p>Fafa turns sounds into abstract street art - depending on your speech input. It is a friend that always listens to you.
          </p>
          <div class ="space-4"></div>
      </div>

      </div>
</div>
</a>
</div>

<div class="card zalando">
  <div class="row">


    <div class="col-lg-6 text">
      <div class="con-text">
        <h5>design to create a sustainable strategic vision</h5>
        <h1>Zalando</h1>
          <p>Using the method of design thinking to create a new business strategy. Thinking outside the box to generate the best user experience.
          </p>
          <div class ="space-4"></div>
      </div>

      </div>
      <div class="col-lg-6 wrapper-bottom">
        <img src="assets/img/zalando.png" id="screen-we">
      </div>
</div>
</div>




<div class="card yellow">

  <div class="row">

        <div class="col-lg-6 wrapper-bottom">
      <img src="assets/img/emoti.png" id="screen-we">
    </div>
    <div class="col-lg-6 text">
      <div class="con-text">
        <h5>study design and usability testing</h5>
          <h1>Emoti</h1>
          <p>Emoti is an app for children to learn emotion management. Based on the concept we derived a study about the effects of avatars on children's ability to manage emotions.
          </p>
          <div class ="space-4"></div>
      </div>

      </div>
</div>

</div>











</div>
