<div class="con space-8">
  <a routerLink="/"><p><img class="back" src="/assets/img/top.png">back</p></a>
</div>


<div class="con space-2">
  <div class="center bg">
    <img  class="space-1 center" id="dybt-start" src="assets/img/dybt-onboarding.png">
    </div>
  <h1>dybt</h1>
  <h3>An attractive, social, human resources-respecting platform in which authenticity and depth of content counts more than retouched perfection.</h3>
  <div class="row space-2">
      <div class="col-7">
  <h4>overview</h4>
<p class="space-1">
There's lots of critics around common social media platform, especially when it comes to the impacts on user. We questioned the ethics behind and found our answers to common problems. What came out is dyt - better for the user and society as a whole.
We followed the Google Design Sprint: Ideate, Build, Learn.
</p>


      </div>
      <div class="col-1"></div>
      <div class="col-4">
          <h4>role</h4>
          <p>research, concept, design, prototyping</p>
           <p class="space-1">2021, in cooperation with Hanno Remmer</p>

        </div>
    </div>


    <div class="row space-4">
      <div class="col-sm-6">
        <div >
          <h3>research</h3>
          <p>All of the problems we identified are caused by the problem that social media does not depict part of our reality. So how can we make an attractive social media platform without these problems?
              </p>
      </div>
      <div class="space-4">
        <h3>ideate</h3>
        <p>All of the problems we identified are caused by the problem that social media does not depict part of our reality. So how can we make an attractive social media platform without these problems?
            </p>
    </div>
      </div>
      <div class="col-sm-6">
          <img id="dybt-start" src="assets/img/dybt-problems.png">
        </div>
      </div>



<div class="row space-4">
  <div class="col-sm-6">
    <img id="dybt-branding" src="assets/img/dybt-start.png">
  </div>
  <div class="col-sm-6">
    <h3 >build & learn</h3>
    <p>The word "dybt" is danish and stands for deep. Despite the meaning, the sound of the word should transports some sort of comfort and hygge feeling. With feedback from our participants we iterated and improved the product.
        </p>

</div>

</div>


  </div>

