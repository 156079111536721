<div class="con space-8">
    <a routerLink="/"><p><img class="back" src="/assets/img/top.png">back</p></a>
  </div>

<div class="center bg">
  <img  class="space-1" id="vree-start" src="assets/img/vree-start.jpg">
</div>
  <div class="con space-2">
    <h1>vree</h1>
    <h3>We combined boxing with freedom. How that works? We just took virtual reality and a combination of boxing experience and a kind of interactive social media. What came out is vree.</h3>
   <h3> 👉 vr + free = vree</h3>
    <div class="row space-2">
        <div class="col-7">
    <h4>overview</h4>
  <p class="space-1">
The QUT students started designing interactive gear suitable for MODBOX practice.
MODBOX stands for Modified form of Boxing and is suited for use at community level. In this form, impacts to the head and neck are prohibited.
</p>
<p>
Based on one of these concepts we focused on the visual design of the interactive interface.
We went through the whole design thinking process: Emphatize, define, ideate, prototype, test.
</p>


        </div>
        <div class="col-1"></div>
        <div class="col-4">
            <h4>role</h4>
            <p>User Research, Interaction, Visual design, Prototyping</p>
             <p class="space-1">2020, in cooperation with Hanno Remmer and the Queensland University of Technology </p>

          </div>
      </div>

      <div class="space-4">
          <h3 >persona</h3>
          <p>The main goal of our UX research was not only to understand what our users do, but especially why they do it.
              The persona helped us to build emphathy, to make decisions, to prioritize tasks and for a better team communication and alignment.
              </p>
      </div>
<div class="row">
  <div class="col-sm-6">
      <img class="persona space-2" src="assets/img/vree-per1.jpg">
  </div>
  <div class="col-sm-6">
      <img class="persona space-2" src="assets/img/vree-per2.jpg">
    </div>
  </div>


      <div class="space-4">
          <h3 >paper prototyping</h3>
          <p>On paper you are willing to try and start from scratch a lot more than in a digital way because it just doesn’t look that perfect and finished.
              So we looked for starting points for our design approach and sketched first ideas.
              Three times we started from scratch. We had to think through the concept over and over again. This process took three weeks where we spent hours ideating.
              Testing different layouts the color range just stayed between black and white. This reduces distraction and helps focusing on the layout and content.
            </p>
      </div>
      <div class="row space-2">
        <div class="col-sm-4 con-img">
            <img class="img" src="/assets/img/vree-pp4.jpeg">
        </div>
        <div class="col-sm-4 con-img">
            <img class="img" src="/assets/img/vree-pp2.jpeg">
          </div>
          <div class="col-sm-4 con-img">
              <img class="img" src="/assets/img/vree-pp3.jpeg">
            </div>
      </div>

      <div class="space-4">
          <h3 >wireframing</h3>
          <p>
              From analog to digital.

              The aim of a wireframe is to provide a visual understanding of a page early in a project.

              We created an interface that specifically focuses on space allocation and prioritization of content, functionalities available, and intended behaviors.
              For these reasons, the wireframes do not include any color.


              Using Adobe XD a clickable prototype evolved from the paper prototypes.
          </p>
      </div>
      <div class="space-2 con-img">
          <img class="img" src="/assets/img/vree-wireframe.png">
        </div>


        <div class="space-4">
            <h3 >design, transitions and animations</h3>
            <p>With the inspiration of our moodboard we did the screen design. The user should perceive the app as evolving, urban, fresh, connecting, dynamic, independent, focused and motivating.
              Based on these considerations, we developed all of the necessary elements like icons and buttons.
              The design was implemented in XD, animations and transitions were added with AfterEffects.
              </p>
              <img class="space-2 img" src="/assets/img/vree-logo.jpg">
        </div>

        <div class="space-4">
            <h3 >storyboard and movie</h3>
        </div>
        <p>Finally we created a movie illustrating the idea behind our app. We thought about the story, setting, music and much more. A part of the storyboard is shown below.
            </p>
        <img class="space-2 img" src="/assets/img/vree-storyboard.png">
    </div>

