import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { WorkComponent } from './components/work/work.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { ImpressumComponent } from './components/impressum/impressum.component';
import { AboutComponent } from './components/about/about.component';
import { WeComponent } from './components/we/we.component';
import { VreeComponent } from './components/vree/vree.component';
import { DybtComponent } from './components/dybt/dybt.component';
import { FafaComponent } from './components/fafa/fafa.component';


@NgModule({
  declarations: [
    AppComponent,
    WorkComponent,
    HeaderComponent,
    FooterComponent,
    ImpressumComponent,
    AboutComponent,
    WeComponent,
    VreeComponent,
    DybtComponent,
    FafaComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
