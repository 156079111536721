<div class="con">

            <h1>Impressum</h1>
            <h3>Verantwortlich
                für die Inhalte (gemäß § 5 TMG / § 55 II RStV)</h3>
            <p>Lisa Pfäffel<br>Lüftenweg 22<br>85072
                Eichstätt</p>
            <p><a target="_blank" href="mailto:lisapfaeffel@web.de" class="link">lisapfaeffel@web.de</a></p>

            <h1 class="space">Haftungsausschluss</h1>
            <h3>Haftung für Inhalte</h3>
            <p>Die Inhalte meiner Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit,
                Vollständigkeit und Aktualität der Inhalte kann ich jedoch keine Gewährleistung
                übernehmen. Als Diensteanbieter bin ich gemäß § 7 Abs.1 TMG für eigene Inhalte auf
                diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG bin ich
                als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde
                Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige
                Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
                Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine
                diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten
                Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werde
                ich diese Inhalte umgehend entfernen.</p>
            <h3>Haftung für Links</h3>
               <p> Mein
                Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte ich keinen
                Einfluss haben. Deshalb kann ich für diese fremden Inhalte auch keine Gewährleitung
                übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder
                Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der
                Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum
                Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der
                verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht
                zumutbar. Bei Bekanntwerden von Rechtsverletzungen werde ich derartige Links umgehend
                entfernen.</p>
            <h3>Urheberrecht</h3>
            <p>Die durch
                die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem
                deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der
                Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen
                Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind
                nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf
                dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter
                beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie
                trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitte ich um einen
                entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werde ich derartige
                Inhalte umgehend entfernen.</p>
            <h3>Datenschutz</h3>
            <p>Die Nutzung
                meiner Webseite ist in der Regel ohne Angabe personenbezogener Daten möglich. Soweit auf
                meiner Seite personenbezogene Daten (beispielsweise Name, Anschrift oder eMail-Adressen)
                erhoben werden, erfolgt dies, soweit möglich, stets auf freiwilliger Basis. Diese Daten
                werden ohne Ihre ausdrückliche Zustimmung nicht an Dritte weitergegeben.&nbsp;Ich weise
                darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per
                E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem
                Zugriff durch Dritte ist nicht möglich.&nbsp;Der Nutzung von im Rahmen der
                Impressumspflicht veröffentlichten Kontaktdaten durch Dritte zur Übersendung von nicht
                ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit ausdrücklich
                widersprochen. Die Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte
                im Falle der unverlangten Zusendung von Werbeinformationen, etwa durch Spam-Mails, vor.
            </p>

        </div>
