<div class="con space-8">
  <a routerLink="/"><p><img class="back" src="/assets/img/top.png">back</p></a>
</div>

<img  class="space-1" id="we-start" src="assets/img/we-start.jpg">
<div class="con space-2">
  <h1>we</h1>
  <h3>In the end, it's not just about the individual but about society as a whole.</h3>
  <div class="row space-2">
    <div class="col-7">
<h4>overview</h4>
<p>What bothers lots of young people is how we are going to be able to live together in the future. I found out that a lot of people want to integrate sustainability in their everyday life but fail.
  Sustainability in day-to-day life is, in fact, rarely a question of a lack or will to do so, and more a problem of the lack of time and motivation, or uncertainty as to what actually is sustainable, authentic and right.
  This is the problem I faced in this project.  </p>
    </div>
    <div class="col-1"></div>
    <div class="col-4">
        <h4>role</h4>
        <p>User Research, Interaction, Visual design, Prototyping & Testing</p>
            <p class="space-1">2020</p>
      </div>
  </div>
<div class="space-4">
  <h3 ><span class="number">1</span> empathize</h3>
  <h4>Fall in love with the problem!</h4>
  <p>The research took days and weeks. Reading studies and statistics about what people think and need when it comes to sutainability helped me a lot to empathize.
    The problem became clearer and more tangible. An online questionaire helped
    to verify my hypothesis. A look at trend analysis confirmed my assumption. Neo-ecology influences our behavior like no other trend.
  </p>
</div>
<div class="row space-4">

  <div class="col-lg-6">
      <h3 ><span class="number">2</span> define</h3>
  <h4>Define the problem!</h4>
  <p>With all the information from research I started defining the problem.
      This resulted in a journey map, persona, market analysis and the final how might we questions.
      At this state I could finally formulate the problem knowing the real user needs: How might we help the user to make his/her everyday life more sustainable?
  </p>
    </div>
    <div class="center col-lg-6">
      <img  id ="we-proto" src="assets/img/we-user.png">
          </div>
</div>



<div class="row space-4">
  <div class="col-lg-6 ">
    <img src="assets/img/we-how.png">



  </div>
    <div class="col-lg-6">

      <h3><span class="number">3</span> ideate</h3>
      <h4>Bring the ideas!</h4>
      <p>Ideation started with answering the how might we questions. Lots of ideas came into my mind. I finally decided for an app -
        a social media platform with challenges about sustainability. The next steps included value propostion canvas, customer journey map,
        storymapping and lean priorisation/kano model.  </p>



    </div>


</div>



<div class="row space-2">
    <div class="col-lg-6">
      <h3 class="space-2"><span class="number">4</span> prototype</h3>
      <h4>Start concept developing!</h4>
      <p>Now the information architecture could finally be build. The design became clearer with creating a moodboard full of inspiration.
        After scribbeling with pen and paper I started wireframing in Adobe XD and prepared a flow chart.
        Defining the design system with colors and typography was the fundament to create realistic mock-ups.
      </p>

      <h3 class="space-4"><span class="number">5</span> evaluate</h3>
      <h4>Test, test, test!</h4>
      <p>The early concept was tested iterativly with participants to really develope the product for the users.</p>

    </div>
    <div class="col-lg-6">
      <img id="wand" src="assets/img/we-storymapping.jpeg">
</div>
</div>


    </div>

<div class="space-4">

  <img  id ="we-proto" src="assets/img/we-proto.jpg">
  </div>

