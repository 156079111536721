<nav>
    <div class="con space-4"  id="header">
      <div class="row">
        <a class="col-sm-8" routerLink="/"><img alt="logo" src="/assets/img/logo.png"></a>
        <ul class="col-sm-4 row">
            <li class="col-sm-6" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
                <a routerLink="/"><h4>work</h4></a>
            </li>
            <li class="col-sm-6" routerLinkActive="active" >
                <a routerLink="/about"><h4>about</h4></a>
            </li>
        </ul>
      </div>
    </div>

</nav>
